import React, { FC } from 'react';
import { WidgetProps } from '@wix/yoshi-flow-editor';
import { PlanListInteractions } from '../../../types/PlanListFedops';
import CssVars from '../../PackagePicker/CssVars';
import List, { ListWidgetProps } from '../../PackagePicker/Widget/List';
import { EmptyStateProps } from '../../PackagePicker/Widget/List/EmptyState';
import { PlanListWidgetContext } from './PlanListWidgetContext';

const Widget: FC<WidgetProps<ListWidgetProps>> = (props) => {
  const emptyState: EmptyStateProps = { isInWidget: true };
  return (
    <PlanListWidgetContext locale={props.locale}>
      <CssVars id={props.host.id} style={props.host.style} />
      <List
        {...props}
        dataHook="PlanList-wrapper"
        interactionEnd={PlanListInteractions.WidgetLoaded}
        emptyState={emptyState}
      />
    </PlanListWidgetContext>
  );
};

export default Widget;
