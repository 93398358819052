import React from 'react';
import { StyleParamType } from '@wix/tpa-settings';
import { useStyles } from '@wix/tpa-settings/react';
import { useEnvironment, IStyle } from '@wix/yoshi-flow-editor';
import { useStylesParams } from '../../hooks';

interface Props {
  id: string;
  style: IStyle;
}

const CssVars: React.FC<Props> = React.memo(({ id, style }) => {
  const styles = useStyles();

  const { isEditor } = useEnvironment();

  const styleParams = useStylesParams();

  const customStyleParamsProperties = Object.values(styleParams).reduce((currentCustomProperties, styleParam) => {
    let nextCustomProperty: string = '';
    switch (styleParam.type) {
      case StyleParamType.Font: {
        const { value, preset, fontStyleParam, style: paramStyle, size, family } = styles.get(styleParam);
        if (fontStyleParam) {
          if (preset === 'Custom') {
            const fontValue = value.split(';')[0].split(':')[1];
            nextCustomProperty += `--${styleParam.key}: ${fontValue};\n`;
            if (value.includes('text-decoration')) {
              nextCustomProperty += `--${styleParam.key}-decoration: underline;`;
            }
          } else {
            nextCustomProperty += `--${styleParam.key}: var(--${preset});\n`;
          }
        } else {
          const fontWeight = paramStyle?.bold ? 'bold' : 'normal';
          const fontSize = size ?? '17';
          const lineHeight = '1.4em';
          const fontStyle = paramStyle?.italic ? 'italic' : 'normal';
          const fontValue = `${fontStyle} ${fontWeight} ${fontSize}px/${lineHeight} ${family}`;
          nextCustomProperty += `--${styleParam.key}: ${fontValue};\n`;
          if (paramStyle?.underline) {
            nextCustomProperty += `--${styleParam.key}-decoration: underline;`;
          }
        }
        break;
      }
      case StyleParamType.Color:
        nextCustomProperty += `--${styleParam.key}: ${styles.get(styleParam).value};`;
        break;
      case StyleParamType.Boolean:
        nextCustomProperty += `--${styleParam.key}: ${styles.get(styleParam) ? 'visible' : 'hidden'};`;
        break;
      case StyleParamType.Number:
        nextCustomProperty += `--${styleParam.key}: ${styles.get(styleParam)};`;
        break;
    }

    return `${currentCustomProperties}\n${nextCustomProperty}`;
  }, '');

  const customTextPresetProperties = Object.entries(style.siteTextPresets)
    .map(([name, textPreset]) => `--${name}: ${textPreset.value.split(':')[1]}`)
    .join('\n');

  const customColorProperties = style.siteColors
    .filter(({ reference }) => reference.startsWith('color'))
    .map(({ reference, value }) => `--${reference}: ${hexToTripplet(value)};`)
    .join('\n');

  const selector = `${isEditor ? '.' : '.'}${id}`;
  return (
    <style type="text/css">
      {`${selector} {
			${customStyleParamsProperties}
			${customTextPresetProperties}
			${customColorProperties}
		}`}
    </style>
  );
});

export default CssVars;

const hexToTripplet = (hex: string): `${string}, ${string}, ${string}` => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `${r}, ${g}, ${b}`;
};
