import React, { memo, useMemo } from 'react';
import { useSettings } from '@wix/tpa-settings/react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { SettingsAdapterProvider, StylesParamsProvider } from '../../../hooks';
import { CurrencyLocaleProvider } from '../../../hooks/useCurrencyLocale';
import type { DefaultSettingsAdapter } from '../DefaultSettingsAdapter';
import type { PackagePickerSettingsParams } from '../settingsParams';
import type { PackagePickerStylesParams } from '../stylesParams';

type WidgetContextProps = {
  settingsAdapterClass: typeof DefaultSettingsAdapter;
  settingsParams: PackagePickerSettingsParams;
  stylesParams: PackagePickerStylesParams;
  locale?: string;
};

export const WidgetContext: React.FC<WidgetContextProps> = memo(
  ({ children, settingsAdapterClass: Adapter, settingsParams, stylesParams, locale }) => {
    const settings = useSettings();
    const settingsAdapter = useMemo(() => new Adapter(settings, settingsParams), [settings]);
    const { isRTL } = useEnvironment();
    return (
      <StylesParamsProvider stylesParams={stylesParams}>
        <SettingsAdapterProvider adapter={settingsAdapter}>
          <CurrencyLocaleProvider locale={locale}>
            <div dir={isRTL ? 'rtl' : 'ltr'}>{children}</div>
          </CurrencyLocaleProvider>
        </SettingsAdapterProvider>
      </StylesParamsProvider>
    );
  },
);
